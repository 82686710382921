<template>
<v-container fluid class="pa-0 py-0 "> 
                     <v-dialog v-model=dialog_nuevoPrestador v-if=dialog_nuevoPrestador persistent width="600px" >                                   
                                        <v-card  dense class="pa-3 ma-0">  
                                                <v-form ref="form" v-model="validado_nuevo_prestador">         
                                                <v-row  dense  class="overline" >
                                                        <v-col   align="center">
                                                                Agregue los datos del nuevo Prestador de Servicios
                                                        </v-col>
                                                </v-row>
                                                <v-divider class="py-2 pa-0"></v-divider>
                                                <v-row dense>
                                                        <v-col cols="12" md="4" class="ma-0 pa-0 px-2">
                                                        <v-text-field  required :rules="textoRules" placeholder=" " autofocus autocomplete="new-password" class="caption" v-model="editItem.nombrePrestador" label="Prestador"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="8" class="ma-0 pa-0 px-2">
                                                        <v-text-field autocomplete="new-password" class="caption" v-model="editItem.direccionPrestador" label="Direccion de Prestador"></v-text-field>
                                                        </v-col>
                                                </v-row>
                                                <v-row dense>
                                                        <v-col cols="12" md="4" class="ma-0 pa-0 px-2">
                                                        <v-text-field   placeholder=" " autocomplete="new-password" class="caption"  v-model="editItem.emailPrestador" label="Email de Prestador"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="4" class="ma-0 pa-0 px-2">
                                                        <v-text-field autocomplete="new-password" class="caption" v-model="editItem.telefonoPrestador" label="Telefono de Prestador"></v-text-field>
                                                        </v-col>
                                                </v-row>
                                                <v-row dense class="pa-0 ma-0">
                                                        <v-col>
                                                                <v-btn :color=colorMenu outlined  small @click="close_nuevoPrestador()">Cancelar</v-btn>                                              
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col>
                                                                <v-btn block :disabled="!validado_nuevo_prestador" :color=colorMenu class="white--text"  small @click="grabar_nuevoPrestador(editItem)">Aceptar</v-btn>
                                                        </v-col>
                                                </v-row> 
                                                </v-form>
                                        </v-card>
                        </v-dialog>
                 <v-form ref="form" v-model="valida_nueva_operacion">     
                 <v-card flat dense class="ma-0 pa-2">                    
                         <v-row dense class="overline" >
                                 <v-col align="center">
                                        Complete los datos del servicio
                                 </v-col>                  
                        </v-row>
                              <v-divider class="py-2 pa-0"></v-divider>                                                    
                                                                        <v-row  dense class="ma-0 pa-0 py-2" >
                                                                                <v-col  cols="10" md="4"> 
                                                                                        <v-select :rules="[v => !!v || 'Requerido']"   autofocus class="caption" persistent-placeholder dense 
                                                                                        v-model="prestadorElegido" item-text='nombre' item-value='idprestador'  :items="prestadores" label="Prestador"></v-select> 
                                                                                </v-col>
                                                                                <v-col cols="2" md="1" class="px-0">       
                                                                                        <v-btn  color="indigo" icon small  @click=abrir_dialog_nuevo() >                
                                                                                        <v-icon  >mdi-plus-circle</v-icon>
                                                                                </v-btn>
                                                                                </v-col>
                                                                                <v-col dense cols="12" md="3" class="px-2">  
                                                                                                                                                                
                                                                                         <v-menu
                                                                                                ref="menu"
                                                                                                v-model="menu"
                                                                                                :close-on-content-click="false"
                                                                                                transition="scale-transition"
                                                                                                offset-y
                                                                                                min-width="290px"
                                                                                        >
                                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                                <v-text-field  :rules="[v => !!v || 'Requerido']" class="ma-0 pa-0 px-2"
                                                                                                :value="formatDate"
                                                                                                label="Fecha de uso"
                                                                                                readonly
                                                                                                dense
                                                                                                v-bind="attrs"
                                                                                                append-icon="mdi-calendar"
                                                                                                                                                                                                v-on="on"
                                                                                                ></v-text-field>
                                                                                                </template>
                                                                                                <v-date-picker
                                                                                                ref="picker"
                                                                                                locale="es"
                                                                                               :min=diahoy
                                                                                               :max=diahoymas
                                                                                                                                                                                             
                                                                                                v-model="voucher.fechauso"
                                                                                                @change="save"
                                                                                                ></v-date-picker>
                                                                                        </v-menu>                                                                       </v-col> 
                                                                        </v-row>
                                                                        <v-row dense class="ma-0 pa-0 px-0">  
                                                                                <v-col cols=7 md=2 class="ma-0 pa-0 px-2" > 
                                                                                        <v-text-field :rules="numerosRules" autocomplete="new-password" class="caption" persistent-placeholder v-model="voucher.numerodocumento" dense  placeholder=" " label="Documento" >
                                                                                        </v-text-field>
                                                                                </v-col >                                                                     
                                                                                <v-col cols="12" md="3" class="ma-0 pa-0 px-2" >
                                                                                        <v-text-field :rules="[v => !!v || 'Requerido']"  autocomplete="new-password"  class="caption" dense v-model="voucher.nombre"   persistent-placeholder   placeholder=" " label="Nombre"></v-text-field>
                                                                                </v-col>
                                                                                <v-col cols="12" md="3" class="ma-0 pa-0 px-2" >
                                                                                        <v-text-field :rules="[v => !!v || 'Requerido']"  autocomplete="new-password"  class="caption" persistent-placeholder dense v-model="voucher.apellido"   placeholder=" " label="Apellido"></v-text-field>
                                                                                </v-col>
                                                                                 <v-col cols="12" md="4"  class="ma-0 pa-0 px-2" >
                                                                                        <v-text-field :rules="emailRules"  autocomplete="new-password" class="caption" dense v-model="voucher.email"   persistent-placeholder   placeholder=" " label=" Email"></v-text-field>
                                                                                </v-col>
                                                                        </v-row>                                                                       
                                                                        <v-row dense class="ma-0 pa-0">                                                                                
                                                                                <v-col cols=12 md=12 class="ma-0 pa-0 px-2 pt-2">
                                                                                        <v-textarea rows="3"  :rules="[v => !!v || 'Requerido']" dense class="caption" v-model="voucher.textoVoucher" label="Texto del Voucher" placeholder=" "  outlined></v-textarea>                                               
                                                                               </v-col>      
                                                                        </v-row>
                                                                        <v-row dense class="ma-0 pa-0">
                                                                                <v-col class="ma-0 pa-0 px-2">
                                                                                        <v-btn outlined :color=colorMenu dark small @click="cancela_nuevo_servicio()">Cancelar</v-btn>
                                                                                </v-col>
                                                                                <v-spacer></v-spacer>
                                                                                  <v-col class="ma-0 pa-0 px-2">
                                                                                        <v-btn :disabled="!valida_nueva_operacion" class="white--text" :color=colorMenu  small @click="guardar_operacion(voucher,0)">Guardar</v-btn>
                                                                                </v-col>                                                                               
                                                                                <v-col class="ma-0 pa-0" v-if=!editando>
                                                                                        <v-btn block :loading="procesando" class="white--text" :disabled="!valida_nueva_operacion" :color=colorMenu  small @click="guardar_operacion(voucher,1)">Guardar y enviar voucher</v-btn>
                                                                                </v-col>
                                                                        </v-row>
                                                </v-card> 
                                </v-form>
                                                <v-snackbar top dense class="ma-0 pa-0" dark v-model="snackbar" :timeout="timeOutSnack" :color="colorSnack">
                                                {{ textoMensaje}}
                                                <template v-slot:action="{ attrs }">
                                                <v-btn class="ma-0 pa-2"
                                                outlined
                                                small
                                                
                                                v-bind="attrs"
                                                @click="snackbar = false"
                                                >
                                                Cerrar
                                                </v-btn>
                                        </template>
                             </v-snackbar>     
</v-container>
</template>
<script>


import moment from 'moment'

export default {
    name: 'nuevoServicio',
    props:{
            dialog_nuevo:{
                          type:Boolean
            },
            retorno:{
                    type:String
            },
            item:{
                    type:Object,
                    default: () => {}
            }
    },    
    mounted:  function() {
                         this.$store.commit('grabarLayout', 'dashboard-layout')                      
                         this.consulta_prestadores()
                         this.valorOriginal={}
                         
                        if (Object.keys(this.item).length>0){ //si viene con algun dato es editar sino nuevo
                                this.editando=true
                                this.valorOriginal=Object.assign({},this.item)
                                this.voucher.nombre=this.item.sc_nombre
                                this.voucher.apellido=this.item.sc_apellido 
                                this.voucher.numerodocumento=this.item.sc_numerodocumento
                                this.voucher.email=this.item.sc_email
                                this.voucher.fechauso=moment(this.item.so_fechauso).format('YYYY-MM-DD')
                                this.prestadorElegido=parseInt(this.item.so_idprestador)
                                this.voucher.textoVoucher=this.item.so_textovoucher
                        }

                        
},
computed:{
          formatDate() {
                        return this.voucher.fechauso
                        ? (moment(this.voucher.fechauso).format('DD/MM/YYYY') )
                        : "";      
                        }
},
data(){
      return{
              editando:false,
              valida_nueva_operacion:true,
              procesando:false,
              textoMensaje:'',
             colorSnack:'success',
             timeOutSnack:6000,
             snackbar:false, 
              diahoy:moment().format("YYYY-MM-DD"),
              diahoymas:moment().add(2,'y').format("YYYY-MM-DD"),
              validado_nuevo_prestador:true,
              textoRules:[ v =>  !!v || 'Requerido' ],
              numerosRules:[  v =>  !!v || 'Requerido' ,  v => /^\d+$/.test(v)  || 'Solo numeros' ],
              emailRules: [  v => !!v || 'Email requerido'  , v => /.+@.+\..+/.test(v)  || 'Email valido requerido' ],        
              dialog_enviar:false,
              dialog_nuevoPrestador:false,
              prestadorElegido:'',
              voucher:{
                      idprestador:'',
                      fechauso:null,
                      numerodocumento:'',
                      email:'',
                      textoVoucher:'',
                      nombre:'',
                      apellido:'',
              },
              activePicker: null,
              yearPicker: true,
              date: null,              
              menu:false,
              prestadores:[],
              colorMenu:"#0E365C",        
              editItem: {},
              datosObtenidos:''
      }      
  },
  
  watch: {
    menu (val) {
      if (this.yearPicker) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR')) 
      } else {
        setTimeout(() => (this.$refs.picker.activePicker = 'DATE'))
      }
    },
  },
    
  methods:{
          cancela_nuevo_servicio(){                
                 this.dialognuevo=false
                this.$emit('update:dialog_nuevo',this.dialognuevo)
          },
          async consulta_prestadores(){
                         await this.$http.get(process.env.VUE_APP_API +'/servicio/prestadores')
                          .then((response) => {
                                              this.prestadores= response.data.data                                                                                 
                          })
                         .catch(function(error){
                                 alert(error)                                 
                         })
          },
          async actualizar_operacion(item,enviar){                                       
                                        this.notificacion('Aguarde unos instantes se esta actualizando la operacion')
                                        this.procesando=true
                                        try {
                                             await this.$http.put(process.env.VUE_APP_API + '/servicio/actualizaroperacion',{params:
                                                {
                                                        idoperacion:this.valorOriginal.so_id,
                                                        idprestador:this.prestadorElegido,
                                                        fechauso:item.fechauso,
                                                        textovoucher:item.textoVoucher,
                                                        nombre:item.nombre,
                                                        apellido:item.apellido,
                                                        numerodocumento:item.numerodocumento,
                                                        email:item.email,
                                                        telefonocelular:item.telefonocelular,
                                                        operacionadicional:enviar
                                                }
                                             })
                                              .then(res =>{
                                                  let retorno= res.data.mensaje
                                                    alert('Al modificar la operacion debera mandarle nuevamente el voucher')
                                                           
                                                   this.dialognuevo=false
                                                   this.procesando=false
                                                   this.$emit('update:dialog_nuevo', this.dialognuevo)
                                                   this.$emit('update:retorno',retorno)
                                          })
                                        } catch (error) {
                                                alert(error)
                                        }                                       
                                        this.procesando=false
          },   
          async guardar_operacion(item,enviar){ // Si enviar=1 cuando sale envia email , si enviar=0 solo guarda
                        this.procesando=true
                        if (Object.keys(this.valorOriginal).length>0){ //si tiene valor tiene que actualizar sino insertar
                                this.actualizar_operacion(item,enviar)
                        }
                        else {
                                 this.notificacion("Aguarde unos instantes se esta generando voucher")
                                 try { 
                                        await this.$http.post(process.env.VUE_APP_API + '/servicio/altaoperacion',{params: 
                                                        {
                                                        idprestador:this.prestadorElegido,
                                                        fechauso:item.fechauso,
                                                        textovoucher:item.textoVoucher,
                                                        nombre:item.nombre,
                                                        apellido:item.apellido,
                                                        numerodocumento:item.numerodocumento,
                                                        email:item.email,
                                                        telefonocelular:item.telefonocelular,
                                                        operacionadicional:enviar,        
                                                        estado:1                                                                 
                                                        }                                                                                                       
                                          })
                                          .then(res =>{
                                                  let retorno= res.data.mensaje          
                                                   this.dialognuevo=false
                                                   this.procesando=false
                                                   this.$emit('update:dialog_nuevo', this.dialognuevo)
                                                   this.$emit('update:retorno',retorno)
                                          })
                                           } catch (error) {
                                                   if (error.response.status==401){
                                                           this.notificacion(error.response.data.mensaje,'ERROR') 
                                                   }
                                                   this.dialognuevo=false
                                                   this.procesando=false
                                                   this.$emit('update:dialog_nuevo', this.dialognuevo)
                                                   this.$emit('update:retorno',"Error al enviar Voucher")
                                         }
                        }
                         this.procesando=false                                          
          }, 
          async grabar_nuevoPrestador(item){                                                               
                                  try { 
                                        await this.$http.post(process.env.VUE_APP_API + '/servicio/altaprestador',{params: 
                                                        {
                                                        nombre:item.nombrePrestador,
                                                        img: item.logoPrestador,
                                                        direccion: item.direccionPrestador,
                                                        localidad: '',                                                       
                                                        provincia: '', 
                                                        pais: '', 
                                                        telefono: item.telefonoPrestador,
                                                        email:item.emailPrestador,  
                                                        estado: 1 
                                                        }                                                                                                       
                                          })
                                          .then(res =>{                                                  
                                                  this.dialognuevoPrestador=false
                                                  this.consulta_prestadores()
                                                  this.prestadorElegido=parseInt(res.data.respuesta)  
                                                  //alert(this.prestadorElegido)                                               
                                          })
                                           } catch (error) {
                                                  this.notificacion("Error al grabar prestador","ERROR")
                                         }
          },
          abrir_dialog_nuevo(){
                                this.dialog_nuevoPrestador=true
          },
          close_nuevoPrestador(){
                        this.dialog_nuevoPrestador=false
          },
          close_enviar(){
                        this.dialog_enviar=false
          },
          close_nuevo(){
                        this.dialognuevo=false
          },
          pathImagen(imagen){
                                return  require('@/assets/'+imagen)  
          },
          colorEstado (estado) { 
                                if (estado=="1") return 'red'
                                 else if (estado =="0") return 'green'
                                else return 'green'
          },
         save (date) {
                                this.$refs.menu.save(date)
         },
         notificacion(mensaje,tipo){   //repetido en listado sacar hacer componente                                   
                                    if (tipo=="ERROR"){
                                                    this.timeOutSnack='-1'
                                                     this.colorSnack='error'
                                                     this.textoMensaje=mensaje
                                                     this.snackbar=true 
                                    }else {
                                             this.timeOutSnack='2000'
                                             this.colorSnack='success'    
                                             this.textoMensaje=mensaje
                                             this.snackbar=true 
                                    }
         }    
  }
}
</script>