<template>
<v-form>
<v-container fluid class="pa-1 py-0 ">  
        <v-row dense>
                <v-col cols=12>
                     <v-row  dense >
                                <v-col cols=12 class="overline ">
                                        Estado de Servicios Rincón Travel 
                                </v-col>
                     </v-row>
                     <v-row dense>
                             <v-col cols=12>
                        <v-card dense >
                                    <v-row dense>
                                        <v-col class="pt-2 px-4 py-2 my-auto" cols=5>                                         
                                                        <v-img contain dense width="100px" :src=img_travel></v-img>                                                
                                        </v-col>      
                                            <v-spacer></v-spacer>
                                            <v-col cols=5 md=2 class="my-auto" >
                                                    <v-btn outlined class="" color="indigo" @click="abrir_dialog_nuevo()" small>NUEVO SERVICIO
                                                    </v-btn>
                                            </v-col>                                            
                                            <v-col cols=12 md=3 class="my-auto" >
                                                     <v-text-field class="px-2 subtitle"
                                                                v-model="buscar"
                                                                append-icon="mdi-magnify"
                                                                label="Buscar"
                                                                single-line
                                                                clearable
                                                                focusable
                                                                dense                                                                
                                                                hide-details
                                                        ></v-text-field>
                                            </v-col>
                                    </v-row>
                                    <v-row dense class="ma-0 pa-0">
                                            <v-col class="ma-0 pa-0">                                    
                                     <v-data-table   no-data-text="Datos no disponibles"
                                        loading="cargando_datos"
                                        loading-text="Obteniendo datos.."               
                                        show-expand
                                        single-expand                                        
                                        dense
                                        :headers="campos"
                                        :items="operaciones"
                                        :search="buscar"
                                        item-key="so_id"
                                        class="elevation-0" 
                                        disable-pagination
                                        :hide-default-footer="true">
                                                <template  v-slot:[`item.so_fechauso`]="{ item }" >
                                                        <span v-if="item.so_fechauso!=null"> {{formatear_fecha(item.so_fechauso)}}</span>
                                                </template> 
                                                <template  v-slot:[`item.so_fechacarga`]="{ item }" >
                                                        <span v-if="item.so_fechacarga!=null"> {{formatear_fecha(item.so_fechacarga)}}</span>
                                                </template>   
                                                <template  v-slot:[`item.so_fechavoucher`]="{ item }" >
                                                        <v-chip v-if="item.so_fechavoucher!=null" dense x-small class="ma-0" 
                                                        color="green" dark>
                                                                <span> {{formatear_fecha(item.so_fechavoucher)}}</span>
                                                        </v-chip>
                                                        <v-chip v-if="item.so_fechavoucher==null" dense x-small class="ma-0" 
                                                        color="red" dark>
                                                                <span>Pendiente</span>
                                                        </v-chip>
                                                </template>
                                                <template v-slot:[`item.acciones`]="{ item }" >
                                                        <v-btn icon small color="indigo"  @click="abrir_generar_enviar(item)">
                                                                <v-icon small>mdi-email-fast</v-icon>
                                                        </v-btn>
                                                        
                                                        <v-btn icon  small color="indigo" @click="abrir_dialog_nuevo(item)" >                
                                                                <v-icon small>mdi-pencil</v-icon>
                                                        </v-btn>
                                                        <v-btn icon  small color="red" @click="borrar_operacion(item)">                
                                                                <v-icon small>mdi-delete</v-icon>
                                                        </v-btn>                                                                   
                                                </template>                                                    
                                </v-data-table>
                                            </v-col>
                                    </v-row>
                                    <v-dialog v-model=dialog_enviar v-if=dialog_enviar persistent width="600px" >                 
                                                <v-card dense class="pa-3 ma-0">
                                                        <v-row dense class="overline" >
                                                                <v-col align="center">
                                                                        Enviar Voucher a cliente 
                                                                </v-col>
                                                        </v-row>
                                                <v-divider class="py-2 pa-0"></v-divider>
                                                <v-row dense class="pa-0 ma-0">
                                                        <v-col cols="12" md="6" class="pa-0 ma-0 px-1">
                                                        <v-text-field disabled v-model="editItem.destinatario" label="Destinatario"></v-text-field>
                                                        </v-col>                                                       
                                                </v-row>
                                            <!--    <v-row dense class="pa-0 ma-0">
                                                        <v-col cols="12" md="12" class="pa-0 ma-0">
                                                        <v-textarea dense disabled rows="3" v-model="editItem.html" label="Mensaje" placeholder=" " outlined></v-textarea>               
                                                        </v-col>                                                         
                                                </v-row>-->
                                                <v-row dense class="pa-0 ma-0">
                                                        <v-col cols="12" md="12" class="pa-0 ma-0">
                                                        <v-textarea dense  disabled rows="5" v-model="editItem.textoVoucher" label="Texto en Voucher"  outlined></v-textarea>               
                                                        </v-col>                                                         
                                                </v-row>
                                                <v-row dense class="pa-0 ma-0">
                                                        <v-col>
                                                                <v-btn :color=colorMenu outlined small @click="close_enviar()">Cancelar</v-btn>
                                                                <v-spacer></v-spacer>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col>
                                                                <v-btn :loading="procesando_enviar" :color=colorMenu  dark small @click="generar_enviar_email(editItem)">Generar y enviar por email</v-btn>
                                                        
                                                        </v-col>
                                                </v-row>                                              
                                        </v-card>
                                 </v-dialog>
                                 <v-dialog v-model=dialog_nuevo v-if=dialog_nuevo persistent width="600px" >                 
                                        <nuevo-servicio :retorno.sync="retorno_nuevo_servicio" :dialog_nuevo.sync="dialog_nuevo" :item="editItemNuevo" ></nuevo-servicio>
                                   <!--  <nuevo-servicio @actualiza_dialog_nuevo="actualiza_dialog_nuevo()"></nuevo-servicio>-->
                                 </v-dialog>
                             </v-card> 
                          <v-snackbar top dense class="ma-0 pa-0" dark v-model="snackbar" :timeout="timeOutSnack" :color="colorSnack">
                                                {{ textoMensaje}}
                                                <template v-slot:action="{ attrs }">
                                                <v-btn class="ma-0 pa-2"
                                                outlined
                                                small                                                
                                                v-bind="attrs"
                                                @click="snackbar = false"
                                                >
                                                Cerrar
                                                </v-btn>
                                        </template>
                             </v-snackbar>                               
                         </v-col>                                
                     </v-row>
                </v-col>                    
        </v-row>          
</v-container>
</v-form>

</template>
<script>

import nuevoServicio from '../rincontravel/nuevoServicio.vue'
import moment from 'moment'
//import notificaciones from '../notificaciones.vue'

export default {
    components:{nuevoServicio}, 
    created: function() {
                         this.$store.commit('grabarLayout', 'dashboard-layout')                                           
  },
  mounted: function() {
                        this.leer_operaciones()
},
  data(){
      return{
             cargando_datos:false,
             procesando_enviar:false,   
             textoMensaje:'',
             colorSnack:'success',
             timeOutSnack:6000,
             snackbar:false,         
             retorno_nuevo_servicio:'',
             dialog_enviar:false,
              dialog_nuevo:false,
              actualizar_operaciones:false,
              editItem: {},
              editItemNuevo:{},
              operaciones:[],
              img_travel:require("@/assets/rincontravel2.png"),
              colorMenu:"#0E365C",   
              buscar:'',              
              expanded: [],
              campos:[
                       {
                               text:'Fecha Carga',
                               sortable:true,
                               value:'so_fechacarga'
                       },
                       {
                               text:'Fecha Uso',
                               sortable:true,
                               value:'so_fechauso'
                       },
                       {
                               text:'Prestador',
                               sortable:true,
                               value:'sp_nombre'
                       }, 
                       {
                               text:'Documento',
                               sortable:true,
                               value:'sc_numerodocumento'
                       },
                       {
                               text:'Titular Servicio',
                               sortable:true,
                               value:'sc_apellido'
                       },                       
                          {
                                text:'Voucher',
                                sortable:true,
                                value:'so_fechavoucher'
                        },                   
                        {
                                text:'Acciones',
                                sortable:false,
                                value:'acciones'
                        }
                ]      
      }
  },
  watch: {
          retorno_nuevo_servicio(val){  
                          if (val){
                                  if (val.indexOf("Error ")==-1){
                                                  this.notificacion(val)
                                  }
                                  else{
                                       this.notificacion( val,"ERROR")   
                                  }  
                          } 
                          this.leer_operaciones()
          }
  },
 
  methods:{    
          async leer_operaciones(){
                  this.cargando_datos=true
                  await this.$http.get(process.env.VUE_APP_API +'/servicio/operaciones')
                          .then((response) => {
                                             this.operaciones= response.data.data
                          })
                         .catch(function(error){
                                 alert(error)                                 
                         }) 
                this.cargando_datos=false 

          },
          formatear_fecha(fecha) {
                  return moment(fecha).format('DD/MM/YY')
          },
          borrar_operacion(item){
                  let index = this.operaciones.indexOf(item)      
                  let respuesta= confirm('Esta seguro de borrar el servicio de : ' +  item.sc_nombre + ' de ' + item.sp_nombre + '?')
                  if (respuesta) {
                        this.$http.delete(process.env.VUE_APP_API +'/servicio/borraroperacion/',  {
                                                params: {   
                                                        id: item.so_id                                                                                  
                                                        }
                                                })
                                                .then((response) => {
                                                if (response.status == 200){  
                                                          this.notificacion("Operacion borrada")
                                                          this.operaciones.splice(index, 1)                          
                                                }
                                                else {
                                                        this.notificacion('Error, operacion no realizada','ERROR')                                                 
                                                }
                                                })
                                                .catch(() =>{
                                                            this.notificacion('Error, operacion no realizada','ERROR')
                                                })                  
                  }
          },          
          abrir_dialog_nuevo(item){                               
                                this.editItemNuevo={}
                                if (item){
                                    this.editItemNuevo=item                                        
                                }
                                 this.dialog_nuevo=true
          },
          abrir_generar_enviar(item){
                       this.dialog_enviar=true
                       this.editItem.destinatario=item.sc_email
                       this.editItem.textoVoucher='Fecha de Uso: ' + moment(item.so_fechauso).format('DD/MM/YY') + '\n' + 'Titular: ' + item.sc_nombre + ' ' + item.sc_apellido + ' • ' + item.sc_numerodocumento + '\n' + item.so_textovoucher 
                       this.editItem.so_id=item.so_id
          },
          close_enviar(){
                        this.dialog_enviar=false
          },
          close_nuevo(){
                        this.dialog_nuevo=false
          },
          async generar_enviar_email(item){
                    this.procesando_enviar=true 
                      this.notificacion("Aguarde unos instantes se esta generando y enviando email")
                    await this.$http.post(process.env.VUE_APP_API +'/servicio/generarenviarpdfvoucherservicio', {
                                params: {
                                    idoperacion:item.so_id   
                                }
                                })
                                .then((response) => { 
                                                   if (response.status == 200) {
                                                           //alert('Llego a 1')
                                                           this.notificacion(response.data.mensaje)                                                                                                                                                        
                                                  }
                                                else{
                                                    //      alert('llego 2')
                                                          this.notificacion(response.data.mensaje,'ERROR')                                                                                                             
                                                    } 
                                 })
                                  .catch(() =>{
                                                            this.notificacion('Error, no se ha podido enviar email','ERROR')
                                                }) 
          this.dialog_enviar=false                       
          this.procesando_enviar=false
          this.leer_operaciones() 
          },
          pathImagen(imagen){ 
                                return  require('@/assets/'+imagen)  
          },
          colorEstado (estado) { 
                                if (estado=="0") return 'red'
                                 else if (estado =="1") return 'green'
                                else return 'green'
                                },
         notificacion(mensaje,tipo){
                                    if (tipo=="ERROR"){
                                                    this.timeOutSnack='-1'
                                                     this.colorSnack='error'
                                                     this.textoMensaje=mensaje
                                                     this.snackbar=true
                                    }else {
                                             this.timeOutSnack='4000'
                                             this.colorSnack='success'    
                                             this.textoMensaje=mensaje
                                             this.snackbar=true 
                                    }
         }
  }
}

</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}
</style>